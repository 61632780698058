import React from 'react';

const HeroWriting = () => {
    return (
        <div className="col-12 hero-writing ">

            <div className='col-lg-10 text-start'>
                <h1>Journey Beyond with Every Play.</h1>
            </div>

            <div className="col-lg-10 text-start">
                <p>
                    Null Frontier is an indie startup based in Denmark, specializing in developing and publishing video games.
                    Motivated by a passion for discovery and driven to push the boundaries of various genres, we craft innovative experiences for players worldwide.
                </p>

                <a href="/games" className="btn btn-primary fs-5 ">Check our games</a>
            </div>
        </div>
    );
};

export default HeroWriting;
