import React from 'react';
import HeroWriting from '../home/heroWriting';
import HeroImage from '../home/heroImage';

const HomePage = () => {
    return (
        <div className="text-center aboutusPage container px-3 py-5">
            <div className="row mx-auto mb-5 border-box">
                <div className="col-lg-8 col-md-12">
                    <HeroWriting />
                </div>
                <div className="col-lg-4 col-md-12 mx-auto">
                    <HeroImage />
                </div>
            </div>


        </div>
    );
};

export default HomePage;


