import React from 'react';
import HerImageFull from '../../assets/images/hero/NullFrontierCobined_1Kblue2.png';

const HeroImage = () => {
    return (
        <div className="hero-image">
            <div className="hero-image-wrapper">
                <img src={HerImageFull}  alt="Spaceship" />
            </div>
        </div>
    );
};

export default HeroImage;
